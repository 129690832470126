import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AddAssignment = Loadable(lazy(() => import('views/assignment/index')));
const EditAssignment = Loadable(lazy(() => import('views/assignment/EditAssignment')));
const AssignmentSetup = Loadable(lazy(() => import('views/assignment/Main')));
// const StudentListByAssignment = Loadable(lazy(() => import('views/assignment/StudentList')));
// const ViewAssignment = Loadable(lazy(() => import('views/assignment/ViewAssignment')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const AssignmentRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/add/assignment',
            element: <AddAssignment />
        },
        {
            path: '/edit/assignment/:id/:givenBy',
            element: <EditAssignment />
        },
        {
            path: '/studentList/byassignments/:id',
            element: <AssignmentSetup />
        }
        // {
        //     path: '/student/viewassignment',
        //     element: <ViewAssignment />
        // }
    ]
};
export default AssignmentRoutes;
